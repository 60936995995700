





































































































import {defineComponent, PropType} from '@vue/composition-api';
import STextarea from '@/common/components/STextarea.vue';
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import FileUploadUiComponent from '@/tasks/components/task-ui-components/FileUploadUiComponent.vue';

export default defineComponent({
  name: 'uOttawa1321Lab6Discussion',
  components: {FileUploadUiComponent, STextarea},
  mixins: [DynamicQuestionMixin()],
  data() {
    const browserLanguage = navigator.language.split('-')[0]; // Extract the language code
    return {
      inputs: {
        discussion: null,
        references: null,
        language: browserLanguage === 'fr' ? 'fr' : 'en', // Set the initial language based on browser preference
      },
      flowChart: [] as File[],
      questions: [
        {
          en: 'Explain your results keeping your experiment in mind. Relate your results to the theory relevant to the experiment.',
          fr: "Expliquez vos résultats en gardant à l'esprit votre expérience. Reliez vos résultats à la théorie pertinente pour l'expérience.",
        },
        {
          en: 'Please upload your flow chart of the workup of the reaction. You should only upload a single PDF file.',
          fr: "Veuillez télécharger votre organigramme de la mise en œuvre de la réaction. Vous ne devez télécharger qu'un seul fichier PDF.",
        },
      ],
    };
  },
  methods: {
    getQuestionText(question: any): string {
      return this.inputs.language === 'en' ? question.en : question.fr;
    },
  },
  computed: {
    attachments(): File[] {
      return [...this.flowChart];
    },
  },
});
